

import styles from './SimuladorModal.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent.js';
import InputText from '../../../../../components/InputText/InputText.js';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal.js';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';

import imagenSpotNoFoto from '../../../../../assets/images/spot-no-image.jpg'

import BotonSecundario from '../../../../../components/boton-secundario/BotonSecundario.js';
import SelectComponent from '../../../../../components/select/SelectComponent.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvBuscarEncuestaParaSimulador } from '../../../../../services/encuestas/srvBuscarEncuestaParaSimulador.js';
import MiniBoton from '../../../../../components/mini-boton/MiniBoton.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { srvBuscarTodosLosEjes } from '../../../../../services/encuestas/srvBuscarTodosLosEjes.js';
import PaginaPrincipal from './pagina-principal/PaginaPrincipal.js';
import PaginaIntroduccion from './pagina-introduccion/PaginaIntroduccion.js';
import PaginaCaritas from './pagina-caritas/PaginaCaritas.js';
import PaginaAlternativas from './pagina-alternativas/PaginaAlternativas.js';
import PaginaFin from './pagina-fin/PaginaFin.js';


function SimuladorPage({ isOpen, onClose, encuesta }) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [ejes, setEjes] = useState([])
    const [paginaActual, setPaginaActual] = useState(1)
    const [paginas, setPaginas] = useState([])
    const [cantidadDePaginas, setCantidadDePaginas] = useState(-1)

    let pagina = null

    const userData = useSelector(state => state.authInfo);


    const [formData, setFormData] = useState({
        idEncuesta: -1,
        titulo: '',
        descripcion: '',
        idEje: -1,
        preguntas: []

    })




    React.useEffect(() => {
        // console.log("encuesta", encuesta)
        console.log("encuesta ppl", encuesta)
        if (encuesta)
            buscarEncuesta(encuesta.idEncuestaHash)

    }, [encuesta])



    React.useEffect(() => {
        console.log("formData ppl2", formData)
        let cantidad = formData.preguntas.length + 3
        setCantidadDePaginas(cantidad)

        let paginas = []

        paginas.push({ tipoPagina: "PRINCIPAL" })

        paginas.push({ tipoPagina: "INTRODUCCION", titulo: formData.titulo, descripcion: formData.descripcion })

        for (let i = 0; i < formData.preguntas.length; i++) {
            if (formData.preguntas[i].tipo_de_pregunta == 1) {
                paginas.push({ tipoPagina: "CARITAS", pregunta: formData.preguntas[i].pregunta, alternativas: [] })
            } else {
                paginas.push({
                    tipoPagina: "ALTERNATIVAS", pregunta: formData.preguntas[i].pregunta,
                    alternativas: formData.preguntas[i].alternativas
                })
            }
        }
        paginas.push({ tipoPagina: "FIN" })

        setPaginas(paginas)
        pagina = paginas[paginaActual - 1];

        console.log("paginas", paginas)

    }, [formData])


    const buscarEncuesta = (idEncuestaHash) => {
        const fetchData = async () => {
            try {
                console.log("idEncuestaHash", idEncuestaHash)
                const encuestaFromServer = await srvBuscarEncuestaParaSimulador(userData, idEncuestaHash);
                console.log("encuestaFromServer", encuestaFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestaFromServer) {
                    // console.log("holi")



                    setFormData(prevFormData => ({
                        ...prevFormData,

                        idEncuesta: encuestaFromServer.data.datos.id_encuesta,
                        titulo: encuestaFromServer.data.datos.glosa_encuesta,
                        descripcion: encuestaFromServer.data.datos.descripcion_larga,
                        idEje: 1,
                        preguntas: encuestaFromServer.data.datos.preguntas

                    }));
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const renderizarPagina = () => {
        console.log("paginas", paginas)
        console.log("paginaActual", paginaActual)

        switch (paginas[paginaActual - 1].tipoPagina) {
            case 'PRINCIPAL':
                return <PaginaPrincipal onSiguiente={irAPaginaSiguiente} />;
            case 'INTRODUCCION':
                return <PaginaIntroduccion onSiguiente={irAPaginaSiguiente} titulo={paginas[paginaActual - 1].titulo} descripcion={paginas[paginaActual - 1].descripcion} />;
            case 'CARITAS':
                return <PaginaCaritas onSiguiente={irAPaginaSiguiente} pregunta={paginas[paginaActual - 1].pregunta} alternativas={paginas[paginaActual - 1].alternativas} numeroPregunta={paginaActual - 2} totalPreguntas={paginas.length - 3} />;
            case 'ALTERNATIVAS':
                return <PaginaAlternativas onSiguiente={irAPaginaSiguiente} pregunta={paginas[paginaActual - 1].pregunta} alternativas={paginas[paginaActual - 1].alternativas} numeroPregunta={paginaActual - 2} totalPreguntas={paginas.length - 3} />;
            case 'FIN':
                return <PaginaFin onSiguiente={irAPrimeraPagina} />;
            default:
                return <div>Página no encontrada</div>;
        }
    };

    const irAPaginaAnterior = () => {
        if (paginaActual > 1) setPaginaActual(paginaActual - 1);
    };

    const irAPaginaSiguiente = () => {
        if (paginaActual < paginas.length) setPaginaActual(paginaActual + 1);
    };

    const irAPrimeraPagina = () => {
        setPaginaActual(1);
    };



    console.log("formData", formData)
    if (!isOpen) return null;
    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.tabletContent}>
                    {paginas.length > 0 && renderizarPagina()}
                    {/* <PaginaPrincipal onSiguiente={irAPaginaSiguiente}></PaginaPrincipal> */}
                </div>
                <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    {

                    }
                    <BotonPrincipal icono={BotonPrincipal.ICONO_VOLVER} onClick={onClose} text="Salir del simulador" ></BotonPrincipal>
                </div>
            </div>
        </div>
    )
}

export default SimuladorPage;