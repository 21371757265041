

import styles from './InfTendenciaPorRespuestaPage.module.css'
import stylesPanel from '../../../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../../../components/header-titulo/HeaderTituloComponent';
import BotonPrincipal from '../../../../../../../components/boton-principal/BotonPrincipal';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../../redux/userActions.js';
import { srvInformeTendenciaPorRespuesta } from '../../../../../../../services/informes/srvInformeTendenciaPorRespuesta.js';
import BarChartPeriodoComponent from '../../../../../../../components/graficos/BarChartPeriodoComponent.js';
import html2pdf from 'html2pdf.js';
import DateInputComponent from '../../../../../../../components/date-input/DateInputComponent.js';


function InfTendenciaPorRespuestaPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [datos, setDatos] = useState([]);

    const [fechaInicial, setFechaInicial] = useState(() => {
        const fechaHoy = new Date();
        const fechaHaceUnAño = new Date();
        fechaHaceUnAño.setFullYear(fechaHoy.getFullYear() - 1);
        return fechaHaceUnAño.toISOString().split('T')[0]; // Formato 'yyyy-mm-dd'
    });

    const [fechaFinal, setFechaFinal] = useState(() => {
        const fechaHoy = new Date();
        return fechaHoy.toISOString().split('T')[0]; // Formato 'yyyy-mm-dd'
    });

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [isEncuestaArchivadasOpen, setIsEncuestaArchivadasOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);




    const location = useLocation();

    const encuesta = location.state ? location.state.encuesta : null;

    console.log(encuesta)

    const generatePDF = () => {

        var options = {
            margin: [0.5, 0.5, 0.5, 0.5], // márgenes en pulgadas: superior, derecha, inferior, izquierda
            filename: 'Informe_Respuestas.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } // Cambia a 'landscape' para ancho mayor
        };

        const element = document.getElementById('report-content');
        html2pdf().set(options).from(element).save();

    };

    useEffect(() => {
        getDatosInforme()
    }, [encuesta, fechaInicial, fechaFinal]);



    const getDatosInforme = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvInformeTendenciaPorRespuesta(userData, encuesta.idEncuestaHash, fechaInicial, fechaFinal);
                // console.log(encuestasFromServer)

                // console.log(encuestasFromServer)

                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestasFromServer.data) {
                    console.log(encuestasFromServer.data.datos)

                    setDatos(encuestasFromServer.data.datos)

                    // setEncuestas([...encuestasFromServer.data, ...encuestasFromServerArchivadas.data])
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    // const formatDataForChart = (pregunta) => {
    //     // Obtener todos los periodos únicos
    //     console.log("pregunta", pregunta)
    //     const periodos = [...new Set(pregunta.respuestas.map((r) => r.periodo))];
    //     console.log("periodos", periodos)

    //     // Obtener todas las alternativas únicas
    //     const alternativas = [...new Set(pregunta.respuestas.map((r) => r.alternativa))];
    //     console.log("alternativas", alternativas)

    //     // Crear la estructura de datasets para cada alternativa
    //     const datasets = alternativas.map((alternativa, index) => {
    //         return {
    //             label: alternativa,
    //             data: periodos.map((periodo) => {
    //                 // Buscar la cantidad de respuestas para cada periodo y alternativa
    //                 const respuesta = pregunta.respuestas.find((r) => r.periodo === periodo && r.alternativa === alternativa);
    //                 return respuesta ? respuesta.cantidad : 0;
    //             }),
    //             backgroundColor: `rgba(${index * 50}, ${100 + index * 20}, ${150 + index * 10}, 0.5)`, // Colores diferentes para cada alternativa
    //             borderColor: `rgba(${index * 50}, ${100 + index * 20}, ${150 + index * 10}, 1)`,
    //             borderWidth: 1,
    //         };
    //     });
    //     console.log("datasets", datasets)
    //     console.log("salida", {
    //         labels: periodos, // Etiquetas en el eje X (periodos)
    //         datasets: datasets, // Datos organizados en series por alternativa
    //     })

    //     const salida = {
    //         labels: periodos, // Etiquetas en el eje X (periodos)
    //         datasets: datasets, // Datos organizados en series por alternativa
    //     }

    //     return salida;
    // };

    console.log("datos", datos)

    return (
        <div className={stylesPanel.containerPrincipal} >
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_INFORMES_ENCUESTA_RESPUESTAS_POR_ENCUESTAS}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <BotonPrincipal icono={BotonPrincipal.ICONO_PDF} text={"Descargar PDF"} onClick={generatePDF}></BotonPrincipal>

                <div id="report-content" >
                    <div style={{ width: 700 }}>


                        <div className={styles.containerFilaBusqueda}>
                            <div> <h3>Tendencia por respuesta</h3>
                                <p>Este informe proporciona un análisis de las tendencias en las respuestas de las encuestas a lo largo del tiempo. Aquí podrás ver cómo han variado las respuestas en diferentes periodos, identificando patrones o cambios significativos en la opinión de los encuestados. Este análisis es útil para comprender el comportamiento y las percepciones de los usuarios en diferentes contextos o momentos del tiempo, y cómo factores externos pueden influir en sus respuestas.</p>

                                <h4>Encuesta: {encuesta.titulo}</h4>
                                <h4>Descripción: {encuesta.descripcion}</h4>
                            </div>


                        </div>

                        <DateInputComponent
                            label="Fecha Inicial"
                            initialValue={fechaInicial}
                            onChange={(newDate) => setFechaInicial(newDate)}
                        />
                        <DateInputComponent
                            label="Fecha Final"
                            initialValue={fechaFinal}
                            onChange={(newDate) => setFechaFinal(newDate)}
                        />

                        {/* <div>
                            <p>Fecha Inicial Seleccionada: {fechaInicial}</p>
                            <p>Fecha Final Seleccionada: {fechaFinal}</p>
                        </div> */}

                        {datos.map((pregunta, index) => (
                            <>
                                <h3>{pregunta.pregunta}</h3>
                                {/* <h4>{formatDataForChart(pregunta)}</h4> */}

                                <BarChartPeriodoComponent data={pregunta}></BarChartPeriodoComponent>
                            </>


                        ))}
                    </div>

                </div>




            </div>




        </div >
    )
}

export default InfTendenciaPorRespuestaPage;