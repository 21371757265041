
import styles from './PrincipalPage.module.css'
import React, { useState, useEffect } from 'react';
import { getAuth } from '../../services/auth/getAuth.js'
import { iniciarSesion } from '../../redux/userActions.js';
import imgLogo from '../../assets/images/logo.png'
import InputText from '../../components/InputText/InputText.js';
import BotonPrincipal from '../../components/boton-principal/BotonPrincipal.js';
import BotonSecundario from '../../components/boton-secundario/BotonSecundario.js';
import EtiquetaError from '../../components/etiqueta-error/EtiquetaError.js';
import HeaderComponent from './header/HeaderComponent.js';
import PanelPage from './panel/PanelPage.js';
import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useLocation, Navigate, Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import EncuestasPage from './panel/encuestas/EncuestasPage.js';
import UsuariosPage from './panel/usuarios/UsuariosPage.js';
import UsuariosEdicionPage from './panel/usuarios/usuario-edicion/UsuariosEdicionPage.js';
import SpotsPage from './panel/spots/SpotsPage.js';
import SpotsEdicionPage from './panel/spots/spots-edicion/SpotsEdicionPage.js';
import LicenciasPage from './panel/licencias/LicenciasPage.js';
import AgregarTotemsPage from './panel/licencias/agregar-totems/AgregarTotemsPage.js';
import ActivarNuevoDispositivoPage from './panel/licencias/agregar-totems/activar-nuevo-dispositivo/ActivarNuevoDispositivoPage.js';
import EncuestasEdicionPage from './panel/encuestas/encuestas-edicion/EncuestasEdicionPage.js';
import SimuladorPage from './panel/encuestas/simulador/SimuladorModal.js';
import ActivacionPage from './panel/encuestas/activacion/ActivacionPage.js';
import PerfilPage from './perfil/PerfilPage.js';
import PerfilEdicionPage from './perfil/perfil-edicion/PerfilEdicionPage.js';
import InformesPage from './panel/informes/InformesPage.js';
import ClientePage from './panel/cliente/ClientePage.js';
import SeleccionEncuestaPage from './panel/spots/seleccion-encuesta/SeleccionEncuestaPage.js';
import InformesEncuestaPage from './panel/informes/informes-encuesta/InformesEncuestaPage.js';
import ListaInformesPage from './panel/informes/informes-encuesta/lista-informes/ListaInformesPage.js';
import InfRespuestasPorEncuestaPage from './panel/informes/informes-encuesta/lista-informes/respuestas-por-encuesta/InfRespuestasPorEncuestaPage.js';
import InfTendenciaPorRespuestaPage from './panel/informes/informes-encuesta/lista-informes/tendencia-por-respuesta/InfTendenciaPorRespuestaPage.js';

function Principal(props) {
    const dispatch = useDispatch();
    const location = useLocation();

    const [errorUsuario, setErrorUsuario] = React.useState("");
    const [errorPassword, setErrorPassword] = React.useState("");
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    const [selected, setSelected] = useState('');

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isDesktop) {
            setIsOpen(true);
        }
    }, [isDesktop]);


    const userData = useSelector(state => state.authInfo);

    console.log("userData", userData)


    // const handleNavigation = (path) => {
    //     navigate(path);
    //     setSelected(path);
    // };


    const handleNavigation = (path) => {
        navigate(path);
    };

    const isSelected = (path) => {
        console.log("ppl1", location.pathname)
        console.log("ppl2", path)
        console.log("ppl3", `/${path}`)
        return location.pathname === `/${path}` ? styles.selected : styles.noSelected;
    };


    const svgPrincipal = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 30 33">
        <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3 -1.5)">
            <path id="Path_15" data-name="Path 15" d="M4.5,13.5,18,3,31.5,13.5V30a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path id="Path_16" data-name="Path 16" d="M13.5,33V18h9V33" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        </g>
    </svg>

    )


    const svgEncuesta = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 29 38">
        <path id="Icon_awesome-clipboard-check" data-name="Icon awesome-clipboard-check" d="M23.625,4.5H18a4.5,4.5,0,0,0-9,0H3.375A3.376,3.376,0,0,0,0,7.875v24.75A3.376,3.376,0,0,0,3.375,36h20.25A3.376,3.376,0,0,0,27,32.625V7.875A3.376,3.376,0,0,0,23.625,4.5ZM13.5,2.813A1.688,1.688,0,1,1,11.813,4.5,1.683,1.683,0,0,1,13.5,2.813Zm8.522,16.3-10.055,9.97a.845.845,0,0,1-1.2-.007L4.964,23.217a.845.845,0,0,1,.007-1.2l2-1.983a.845.845,0,0,1,1.2.007L11.4,23.309l7.453-7.4a.845.845,0,0,1,1.2.007l1.983,2A.845.845,0,0,1,22.022,19.111Z" transform="translate(1 1)" fill="none" stroke="#000" stroke-width="2" />
    </svg>)


    const svgSpots = (<svg xmlns="http://www.w3.org/2000/svg" height="23px" width={30} style={{ marginRight: 10 }} viewBox="0 0 40.087 64.139">
        <path id="Icon_metro-location" data-name="Icon metro-location" d="M28.4,1.928A20.043,20.043,0,0,0,8.355,21.971c0,20.043,20.043,44.1,20.043,44.1s20.043-24.052,20.043-44.1A20.043,20.043,0,0,0,28.4,1.928Zm0,32.32A12.277,12.277,0,1,1,40.675,21.971,12.277,12.277,0,0,1,28.4,34.248ZM20.631,21.971A7.767,7.767,0,1,1,28.4,29.738,7.767,7.767,0,0,1,20.631,21.971Z" transform="translate(-8.355 -1.928)" />
    </svg>
    )


    const svgUsuarios = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 36 30.13">
        <g id="Icon_feather-users" data-name="Icon feather-users" transform="translate(0 -2.87)">
            <path id="Path_17" data-name="Path 17" d="M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path id="Path_18" data-name="Path 18" d="M19.5,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path id="Path_19" data-name="Path 19" d="M34.5,31.5v-3A6,6,0,0,0,30,22.7" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path id="Path_20" data-name="Path 20" d="M24,4.7A6,6,0,0,1,24,16.32" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        </g>
    </svg>
    )


    const svgClientes = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 50.016 41.68">
        <path id="Icon_map-museum" data-name="Icon map-museum" d="M9.337,18.188h4.08l.627,18.756H8.794l.543-18.756ZM5.93,37.986h39.6V40.07h3.126V43.2h2.084V45.28H.72V43.2H2.8V40.07H5.93Zm41.68-25.2L25.73,3.6,3.846,12.782V14.02H47.61V12.782Zm-39.6,2.28H43.442v2.084H8.014Zm29.943,3.126h4.078l.632,18.756H37.41l.547-18.756Zm-9.536,0H32.5l.625,18.756H27.871Zm-9.538,0h4.079l.621,18.756H18.332l.55-18.756Z" transform="translate(-0.72 -3.6)" />
    </svg>
    )

    const svgLicencias = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 32.999 32.12">
        <path id="Icon_feather-key" data-name="Icon feather-key" d="M31.5,3l-3,3M17.085,17.415a8.25,8.25,0,1,1-11.668,0,8.25,8.25,0,0,1,11.668,0Zm0,0L23.25,11.25m0,0,4.5,4.5L33,10.5,28.5,6m-5.25,5.25L28.5,6" transform="translate(-1.501 -0.879)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
    </svg>
    )

    const svgInformes = (<svg xmlns="http://www.w3.org/2000/svg" height="18px" width={30} style={{ marginRight: 10 }} viewBox="0 0 36 27">
        <path id="Icon_awesome-chart-bar" data-name="Icon awesome-chart-bar" d="M23.4,22.5h2.7a.968.968,0,0,0,.9-.9V12.15a.968.968,0,0,0-.9-.9H23.4a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9Zm6.75,0h2.7a.968.968,0,0,0,.9-.9V5.4a.968.968,0,0,0-.9-.9h-2.7a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9ZM9.9,22.5h2.7a.968.968,0,0,0,.9-.9V16.65a.968.968,0,0,0-.9-.9H9.9a.968.968,0,0,0-.9.9V21.6a.968.968,0,0,0,.9.9Zm6.75,0h2.7a.968.968,0,0,0,.9-.9V7.65a.968.968,0,0,0-.9-.9h-2.7a.968.968,0,0,0-.9.9V21.6A.968.968,0,0,0,16.65,22.5ZM34.875,27H4.5V5.625A1.125,1.125,0,0,0,3.375,4.5H1.125A1.125,1.125,0,0,0,0,5.625V29.25A2.25,2.25,0,0,0,2.25,31.5H34.875A1.125,1.125,0,0,0,36,30.375v-2.25A1.125,1.125,0,0,0,34.875,27Z" transform="translate(0 -4.5)" fill="#000" />
    </svg>
    )

    const svgPerfil = (<svg xmlns="http://www.w3.org/2000/svg" height="20px" width={30} style={{ marginRight: 10 }} viewBox="0 0 27 30">
        <g id="Icon_feather-user" data-name="Icon feather-user" transform="translate(-4.5 -3)">
            <path id="Path_1" data-name="Path 1" d="M30,31.5v-3a6,6,0,0,0-6-6H12a6,6,0,0,0-6,6v3" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
            <path id="Path_2" data-name="Path 2" d="M24,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
        </g>
    </svg>)


    return (
        <div className={styles.container}>



            <HeaderComponent onClickMenu={() => setIsOpen(!isOpen)}></HeaderComponent>
            <div className={styles.divCuerpo}>
                <aside className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
                    <nav>
                        <ul className={styles.listaMenu}>
                            <a onClick={() => handleNavigation('')}>
                                <div className={location.pathname === `/principal` ? styles.selected : styles.noSelected}><li className={styles.itemMenu}>{svgPrincipal}Principal</li></div>
                            </a>

                            <a onClick={() => handleNavigation('licencias')}>
                                <div className={location.pathname === `/principal/licencias` ? styles.selected : styles.noSelected}><li>{svgLicencias}Licencias</li></div>
                            </a>
                            <a onClick={() => handleNavigation('spots')}>
                                <div className={location.pathname.startsWith(`/principal/spots`) ? styles.selected : styles.noSelected}><li>{svgSpots}Spots</li></div>
                            </a>
                            <a onClick={() => handleNavigation('encuestas')}>
                                <div className={location.pathname.startsWith(`/principal/encuestas`) ? styles.selected : styles.noSelected}><li>{svgEncuesta}Encuestas</li></div>
                            </a>
                            <a onClick={() => handleNavigation('usuarios')}>
                                <div className={location.pathname.startsWith(`/principal/usuarios`) ? styles.selected : styles.noSelected}><li>{svgUsuarios}Usuarios</li></div>
                            </a>
                            {userData.usuario.idUsuarioTipo == 1 && (
                                <a onClick={() => handleNavigation('cliente')}>
                                    <div className={location.pathname.startsWith(`/principal/cliente`) ? styles.selected : styles.noSelected}><li>{svgClientes}Cliente</li></div>
                                </a>
                            )}


                            <a onClick={() => handleNavigation('informes')}>
                                <div className={location.pathname === `/principal/informes` ? styles.selected : styles.noSelected}><li>{svgInformes}Informes</li></div>
                            </a>
                            <hr />
                            <a onClick={() => handleNavigation('mi-perfil')}>
                                <div className={location.pathname.startsWith(`/principal/mi-perfil`) ? styles.selected : styles.noSelected}><li>{svgPerfil}Mi perfil</li></div>
                            </a>
                        </ul>
                    </nav>
                </aside>
                <div className={styles.container}>
                    {/* Configuración de las rutas anidadas */}
                    <Routes>
                        <Route path="encuestas" element={<EncuestasPage />} />
                        <Route path="usuarios" element={<UsuariosPage />} />
                        <Route path="usuarios/edicion" element={<UsuariosEdicionPage />} />


                        <Route path="spots" element={<SpotsPage />} />
                        <Route path="spots/edicion" element={<SpotsEdicionPage />} />
                        <Route path="spots/agregar-totems" element={<AgregarTotemsPage />} />
                        <Route path="spots/agregar-totems/activar-nuevo-dispositivo" element={<ActivarNuevoDispositivoPage />} />
                        <Route path="spots/seleccion-encuesta" element={<SeleccionEncuestaPage />} />

                        <Route path="licencias" element={<LicenciasPage />} />
                        <Route path="licencias/agregar-totems" element={<AgregarTotemsPage />} />
                        <Route path="licencias/agregar-totems/activar-nuevo-dispositivo" element={<ActivarNuevoDispositivoPage />} />

                        <Route path="encuestas" element={<EncuestasPage />} />
                        <Route path="encuestas/edicion" element={<EncuestasEdicionPage />} />
                        <Route path="encuestas/simulador" element={<SimuladorPage />} />
                        <Route path="encuestas/activar" element={<ActivacionPage />} />

                        <Route path="informes" element={<InformesPage />} />
                        <Route path="informes/informes-encuesta" element={<InformesEncuestaPage />} />
                        <Route path="informes/informes-encuesta/lista-informes" element={<ListaInformesPage />} />
                        <Route path="informes/informes-encuesta/lista-informes/informe-respuesta-por-encuesta" element={<InfRespuestasPorEncuestaPage />} />
                        <Route path="informes/informes-encuesta/lista-informes/informe-tendencia-por-respuesta" element={<InfTendenciaPorRespuestaPage />} />


                        <Route path="cliente" element={<ClientePage />} />

                        <Route path="mi-perfil" element={<PerfilPage />} />
                        <Route path="mi-perfil/edicion" element={<PerfilEdicionPage />} />





                        {/* Puedes tener una ruta predeterminada dentro de /principal si lo necesitas */}
                        <Route index element={<PanelPage />} />
                    </Routes>
                </div>
            </div>

            {/* <Routes> 
                <Route path="/panel" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <PanelPage />} />
                <Route path="/panel/encuestas" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <EncuestasPage />} /> 
            </Routes> */}


        </div>
    )
}

export default Principal;