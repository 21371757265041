

import styles from './InfRespuestasPorEncuestaPage.module.css'
import stylesPanel from '../../../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../../../components/header-titulo/HeaderTituloComponent';
import BotonPrincipal from '../../../../../../../components/boton-principal/BotonPrincipal';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../../redux/userActions.js';
import { srvInformeRespuestasPorEncuesta } from '../../../../../../../services/informes/srvInformeRespuestasPorEncuesta.js';
import BarChartComponent from '../../../../../../../components/graficos/BarChartComponent.js';
import html2pdf from 'html2pdf.js';
import DateInputComponent from '../../../../../../../components/date-input/DateInputComponent.js';


function InfRespuestasPorEncuestaPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [datos, setDatos] = useState([]);

    const [fechaInicial, setFechaInicial] = useState(() => {
        const fechaHoy = new Date();
        const fechaHaceUnAño = new Date();
        fechaHaceUnAño.setFullYear(fechaHoy.getFullYear() - 1);
        return fechaHaceUnAño.toISOString().split('T')[0]; // Formato 'yyyy-mm-dd'
    });

    const [fechaFinal, setFechaFinal] = useState(() => {
        const fechaHoy = new Date();
        return fechaHoy.toISOString().split('T')[0]; // Formato 'yyyy-mm-dd'
    });

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [isEncuestaArchivadasOpen, setIsEncuestaArchivadasOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);




    const location = useLocation();

    const encuesta = location.state ? location.state.encuesta : null;

    console.log(encuesta)

    const generatePDF = () => {

        var options = {
            margin: [0.5, 0.5, 0.5, 0.5], // márgenes en pulgadas: superior, derecha, inferior, izquierda
            filename: 'Informe_Respuestas.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' } // Cambia a 'landscape' para ancho mayor
        };

        const element = document.getElementById('report-content');
        html2pdf().set(options).from(element).save();

    };

    useEffect(() => {
        getDatosInforme()
    }, [encuesta, fechaInicial, fechaFinal]);



    const getDatosInforme = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvInformeRespuestasPorEncuesta(userData, encuesta.idEncuestaHash, fechaInicial, fechaFinal);
                // console.log(encuestasFromServer)

                // console.log(encuestasFromServer)

                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestasFromServer.data) {
                    console.log(encuestasFromServer.data.datos)
                    setDatos(encuestasFromServer.data.datos)

                    // setEncuestas([...encuestasFromServer.data, ...encuestasFromServerArchivadas.data])
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }



    const analizar = (encuestaSelected) => {

        navigate("lista-informes", { state: { encuesta: encuestaSelected } })
    }

    return (
        <div className={stylesPanel.containerPrincipal} >
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_INFORMES_ENCUESTA_RESPUESTAS_POR_ENCUESTAS}></HeaderTituloComponent>

            <div className={stylesPanel.containerPanel}>
                <BotonPrincipal icono={BotonPrincipal.ICONO_PDF} text={"Descargar PDF"} onClick={generatePDF}></BotonPrincipal>

                <div id="report-content" >
                    <div style={{ width: 700 }}>


                        <div className={styles.containerFilaBusqueda}>
                            <div> <h3>Detalle de Respuestas por Encuesta</h3>
                                <p>Un análisis detallado de las respuestas de cada encuesta. Aquí puedes ver cuántas personas respondieron cada pregunta y cómo se distribuyeron las respuestas. Este informe es útil para evaluar el impacto de cada encuesta y entender mejor la opinión de los encuestados</p>

                                <h4>Encuesta: {encuesta.titulo}</h4>
                                <h4>Descripción: {encuesta.descripcion}</h4>
                            </div>


                        </div>

                        <DateInputComponent
                            label="Fecha Inicial"
                            initialValue={fechaInicial}
                            onChange={(newDate) => setFechaInicial(newDate)}
                        />
                        <DateInputComponent
                            label="Fecha Final"
                            initialValue={fechaFinal}
                            onChange={(newDate) => setFechaFinal(newDate)}
                        />

                        {/* <div>
                            <p>Fecha Inicial Seleccionada: {fechaInicial}</p>
                            <p>Fecha Final Seleccionada: {fechaFinal}</p>
                        </div> */}

                        {datos.map((pregunta, index) => (
                            <>
                                <h3>{pregunta.pregunta}</h3>

                                <BarChartComponent data={pregunta.respuestas} />
                            </>


                        ))}
                    </div>

                </div>




            </div>




        </div >
    )
}

export default InfRespuestasPorEncuestaPage;