

import styles from './SeleccionPreguntaPerfilModal.module.css'
import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../redux/userActions.js';

import BotonSecundario from '../../../../../../components/boton-secundario/BotonSecundario.js';
import { srvBuscarPreguntasPerfil } from '../../../../../../services/preguntas-perfil/srvBuscarPreguntasPerfil.js';
import MiniBoton from '../../../../../../components/mini-boton/MiniBoton.js';
import EtiquetaError from '../../../../../../components/etiqueta-error/EtiquetaError.js';
import PreguntaEdicionModal from '../pregunta-edicion/PreguntaEdicionModal.js';
import { srvNuevaPreguntaPerfil } from '../../../../../../services/preguntas-perfil/srvNuevaPreguntaPerfil.js';


function SeleccionPreguntaPerfilModal({ isOpen, onClose, onSelected, listPreguntasPerfilSeleccionadas }) {



    const dispatch = useDispatch();
    // dispatch(cerrarSesion()); 
    const [preguntasPerfil, setPreguntasPerfil] = useState([]);
    const [error, setError] = useState("");


    const [isModalPreguntaEdicionOpen, setIsModalPreguntaEdicionOpen] = useState(false);
    const [isModalEdicionPregunta, setIsModalEdicionPregunta] = useState(false);

    const [isReadOnly, setIsReadOnly] = useState(false)

    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null);

    const userData = useSelector(state => state.authInfo);

    // console.log("listPreguntasPerfilSeleccionadas", listPreguntasPerfilSeleccionadas)

    React.useEffect(() => {
        buscarPreguntasPerfil()
    }, [])




    const buscarPreguntasPerfil = () => {
        const fetchData = async () => {
            try {
                const preguntasPerfilFromServer = await srvBuscarPreguntasPerfil(userData);
                console.log(preguntasPerfilFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (preguntasPerfilFromServer) {
                    console.log("holi")

                    setPreguntasPerfil(preguntasPerfilFromServer.data.datos)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }

    const handleSeleccionarPregunta = (pregunta) => {
        onSelected(pregunta)
    }

    const onChangePregunta = (preguntaEditada) => {
        console.log("Pregunta", preguntaEditada)

        const fetchData = async () => {
            try {
                const preguntasPerfilFromServer = await srvNuevaPreguntaPerfil(userData, preguntaEditada);
                console.log(preguntasPerfilFromServer)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (preguntasPerfilFromServer) {
                    console.log("holi")

                    buscarPreguntasPerfil()
                }
                // navigate("/panel");
                else
                    setError("Error al buscar los usuarios")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();


        setIsModalPreguntaEdicionOpen(false)
    }

    if (!isOpen) return null;


    return (

        <div style={{ zIndex: 1000, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5, width: '50vw', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                <div className={styles.divFormulario}>


                    <div className={styles.modalHeader}>
                        <h3>Selección de pregunta de perfil</h3>
                        <p>Estas preguntas tienen la finalidad de poder segmentar cada input de la encuesta de acuerdo a rango de edad, sexo, etc y tener un mejor entendimiento de los resultados de la encuesta </p>

                    </div>
                    <div className={styles.modalContent}>
                        <div className={styles.contenedorPreguntas}>
                            {preguntasPerfil && preguntasPerfil.map((pregunta, index) => (
                                <a onClick={() => listPreguntasPerfilSeleccionadas.includes(pregunta.idPreguntaPerfil) ? null : handleSeleccionarPregunta(pregunta)}>
                                    <div className={listPreguntasPerfilSeleccionadas.includes(pregunta.idPreguntaPerfil) ? styles.preguntaFondoIncluida : styles.preguntaFondoNoIncluida} >
                                        <div className={styles.divPregunta}>
                                            <div className={styles.pregunta}>{pregunta.preguntaPerfil}</div>
                                            <div className={styles.cantAlternativas}>{pregunta.preguntaTipo} (x{pregunta.cantAlternativas})</div>
                                        </div>
                                        <div className={styles.divBotones}>
                                            <MiniBoton icono={MiniBoton.ICONO_VER}></MiniBoton>
                                        </div>
                                    </div>

                                </a>

                            ))}
                        </div>
                    </div>
                    <div className={styles.modalFooter}>
                        <div className={styles.divBotonera}>
                            <BotonSecundario text="Cancelar" onClick={onClose}></BotonSecundario>

                        </div>
                        <div className={styles.divBotonera}>
                            <BotonSecundario text="Nueva pregunta" onClick={() => setIsModalPreguntaEdicionOpen(true)}></BotonSecundario>

                        </div>
                        <EtiquetaError mensajeError={error} ></EtiquetaError>
                    </div>



                </div>

            </div>


            <PreguntaEdicionModal
                isOpen={isModalPreguntaEdicionOpen}
                onClose={() => setIsModalPreguntaEdicionOpen(false)}
                titulo="Nueva pregunta de perfil"
                preguntaOriginal={preguntaSeleccionada}
                isReadOnly={isReadOnly}
                isPreguntaDePerfil={true}
                onChange={onChangePregunta}
            />

        </div>



    )
}

export default SeleccionPreguntaPerfilModal;