import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Registrar los componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = ({ data = [] }) => {

    const formattedData = {
        labels: data.map(item => item.alternativa), // Etiquetas con las alternativas
        datasets: [{
            label: 'Cantidad de respuestas', // Título del dataset
            data: data.map(item => item.cantidad), // Cantidades de respuestas
            backgroundColor: data.map((_, index) => index % 2 === 0 ? 'rgba(75, 192, 192, 0.5)' : 'rgba(153, 102, 255, 0.5)'), // Alternar colores
            borderColor: data.map((_, index) => index % 2 === 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(153, 102, 255, 1)'),
            borderWidth: 1,
            barThickness: 10,
        }]
    };



    const options = {
        indexAxis: 'y', // Gráfico de barras horizontales
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 14 // Tamaño de fuente para las etiquetas del eje x
                    }
                },
                beginAtZero: true,
            },
            y: {
                ticks: {
                    font: {
                        size: 20 // Tamaño de fuente para las etiquetas del eje y
                    }
                }
            }
        },
    };

    return <Bar data={formattedData} options={options} />;
};

export default BarChartComponent;