

import styles from './InformesEncuestaPage.module.css'
import stylesPanel from '../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../components/header-titulo/HeaderTituloComponent';
import InputText from '../../../../../components/InputText/InputText';
import BotonPrincipal from '../../../../../components/boton-principal/BotonPrincipal';
import { srvBuscarTodasLasEncuestas } from '../../../../../services/encuestas/srvBuscarTodasLasEncuestas';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../redux/userActions.js';
import { srvArchivarEncuesta } from '../../../../../services/encuestas/srvArchivarEncuesta.js';
import ConfirmationModalComponent from '../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvEliminarEncuesta } from '../../../../../services/encuestas/srvEliminarEncuesta.js';
import SimuladorPage from './../../encuestas/simulador/SimuladorModal.js';
import EncuestasArchivadasPage from './../../encuestas/encuestas-archivadas/EncuestasArchivadasPage.js';

function InformesEncuestaPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [isEncuestaArchivadasOpen, setIsEncuestaArchivadasOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);

    React.useEffect(() => {
        getEncuestasFromServer()
    }, [])


    // useEffect(() => {
    //     console.log("Nuevo texto a buscar ", textoBuscado)
    // }, [textoBuscado]);


    const getEncuestasFromServer = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvBuscarTodasLasEncuestas(userData, "0");
                // console.log(encuestasFromServer)

                const encuestasFromServerArchivadas = await srvBuscarTodasLasEncuestas(userData, "1");
                // console.log(encuestasFromServer)

                // console.log("authorization", userInfo.headers.getAuthorization)
                if (encuestasFromServer.data || encuestasFromServerArchivadas.data) {
                    console.log(encuestasFromServer.data)

                    setEncuestas([...encuestasFromServer.data, ...encuestasFromServerArchivadas.data])
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData();
    }


    const handleInputChange = (e) => {

        const { name, value } = e.target;
        console.log("Texto buscado", value)
        setTextoBuscado(value)

    };

    const analizar = (encuestaSelected) => {

        navigate("lista-informes", { state: { encuesta: encuestaSelected } })
    }
    const eliminar = () => {
        const fetchData = async () => {
            try {
                const encuestasFromServer = await srvEliminarEncuesta(userData, encuestaSelected.idEncuestaHash);
                console.log(encuestasFromServer)
                if (encuestasFromServer.data) {
                    getEncuestasFromServer()
                }
                else
                    setError("Error al buscar las encuestas")
            } catch (error) {
                console.log("error.message", "+" + error + "+")
                if (error == 'Error: Error del servidor: 401') {
                    console.log("ahora si 401")
                    dispatch(cerrarSesion());
                } else {
                    setError('Error al procesar la solicitud');
                }


            }
        };
        fetchData()
    }
    const simular = (encuesta) => {
        console.log("Simulando")
        setEncuestaSelected(encuesta)
        setIsModalSimuladorOpen(true)

    }

    const pedirConfirmacion = (encuesta, accion) => {
        setEncuestaSelected(encuesta)
        setAccionAConfirmar(accion)
        setIsOpenModal(true)
    }

    const accionConfirmada = () => {
        setIsOpenModal(false)
        console.log(accionAConfirmar)
        switch (accionAConfirmar.accion) {
            case ACCION_MODIFICAR.accion:
                navigate("edicion", { state: { encuesta: encuestaSelected } })
                break
            case ACCION_ELIMINAR.accion:
                eliminar()
                break
            case ACCION_ACTIVAR.accion:
                abrirActivacionPage()
                break
            default:
                break
        }
    }

    const abrirActivacionPage = (encuesta) => {
        setEncuestaSelected(encuesta)
        navigate("activar", { state: { encuesta: encuesta } })
    }

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_INFORMES_ENCUESTA_PRINCIPAL}></HeaderTituloComponent>
            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerFilaBusqueda}>
                    <InputText
                        id="buscar"
                        name="buscar"
                        value={textoBuscado}
                        type={"search"}
                        onChangeInput={handleInputChange}
                        placeholder={"Busca encuesta..."}
                        mensajeError={errorBuscar}></InputText>
                    {/* <BarraBuscar handleNuevaBusqueda={handleNuevaBusqueda} toolTip={"Busca encuestas"} habilitarBusqueda={true}></BarraBuscar> */}



                </div>



                <div className={styles.containerGrillaUsuarios}  >
                    {encuestas.map((encuesta, index) => (
                        <div key={index} className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                            <label className={styles.titulo}>{encuesta.titulo}</label>
                            <div className={styles.divTodoEnUnaFila}>

                                <div className={styles.divCampos} >

                                    {encuesta.swArchivada == 1 && (
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo} style={{ backgroundColor: 'yellow' }}>ENCUESTA ARCHIVADA</label>
                                        </div>
                                    )}


                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Descripcion:</label>
                                        <label className={styles.valor}>{encuesta.descripcion}</label>
                                    </div>
                                    {encuesta.swModificable == 0 && (
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Esta encuesta no es modificable</label>
                                        </div>
                                    )}


                                    {encuesta.swArchivada == 1 && (
                                        <div className={styles.divCamposItem}>
                                            <label className={styles.atributo}>Esta encuesta está archivada por lo que no puedes activarla pero si puedes ver sus resultados</label>
                                        </div>
                                    )}


                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Creado por:</label>
                                        <label className={styles.valor}>{encuesta.usuarioCreador}</label>
                                    </div>
                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Fecha creación:</label>
                                        <label className={styles.valor}>{encuesta.fechaCreacion}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Spots usando esta encuesta:</label>
                                        <label className={styles.valor}>{encuesta.spotsConEncuestaActiva}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Totems usando esta encuesta:</label>
                                        <label className={styles.valor}>{encuesta.totemsConEncuestaActiva}</label>
                                    </div>

                                    <div className={styles.divCamposItem}>
                                        <label className={styles.atributo}>Respuestas ingresadas a esta encuesta:</label>
                                        <label className={styles.valor}>{encuesta.cantidadRespuestas}</label>
                                    </div>
                                </div>
                            </div>


                            <div className={styles.divBotonera}>


                                <BotonPrincipal text="Analizar" onClick={() => analizar(encuesta)} icono={BotonPrincipal.ICONO_ANALIZAR} />
                                <BotonPrincipal text="Simular" onClick={() => simular(encuesta)} icono={BotonPrincipal.ICONO_SIMULAR} />

                            </div>

                        </div>
                    ))}


                </div>

            </div>


            <SimuladorPage
                isOpen={isModalSimuladorOpen}
                onClose={() => setIsModalSimuladorOpen(false)}
                encuesta={encuestaSelected}

            />

            <EncuestasArchivadasPage
                isOpen={isEncuestaArchivadasOpen}
                onClose={() => setIsEncuestaArchivadasOpen(false)}
            />



            <ConfirmationModalComponent
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                onConfirm={accionConfirmada}
                title={accionAConfirmar.titulo}>
                {accionAConfirmar.mensaje}
            </ConfirmationModalComponent>


        </div >
    )
}

export default InformesEncuestaPage;