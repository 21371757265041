// DateInputComponent.js
import React, { useState, useEffect } from 'react';

const DateInputComponent = ({ label, initialValue, onChange }) => {
    const [date, setDate] = useState(initialValue);

    useEffect(() => {
        setDate(initialValue);
    }, [initialValue]);

    const handleDateChange = (e) => {
        setDate(e.target.value);
        if (onChange) {
            onChange(e.target.value);
        }
    };

    return (
        <div>
            <label htmlFor={`date-input-${label}`}>{label}:</label>
            <input
                type="date"
                id={`date-input-${label}`}
                name={`date-input-${label}`}
                value={date}
                onChange={handleDateChange}
                min="1900-01-01" // Puedes ajustar estos valores según necesites
                max="2100-12-31"
            />
        </div>
    );
};

export default DateInputComponent;