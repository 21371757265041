

import styles from './ListaInformesPage.module.css'
import stylesPanel from '../../../../../../styles/Panel.module.css'
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';
import HeaderTituloComponent from '../../../../../../components/header-titulo/HeaderTituloComponent';
import InputText from '../../../../../../components/InputText/InputText';
import BotonPrincipal from '../../../../../../components/boton-principal/BotonPrincipal';
import { srvBuscarTodasLasEncuestas } from '../../../../../../services/encuestas/srvBuscarTodasLasEncuestas';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../../../../redux/userActions.js';
import ConfirmationModalComponent from '../../../../../../components/confirmation-modal/ConfirmationModalComponent.js';
import { srvEliminarEncuesta } from '../../../../../../services/encuestas/srvEliminarEncuesta.js';
import SimuladorPage from './../../../encuestas/simulador/SimuladorModal.js';
import EncuestasArchivadasPage from './../../../encuestas/encuestas-archivadas/EncuestasArchivadasPage.js';

function ListaInformesPage(props) {
    const dispatch = useDispatch();
    // dispatch(cerrarSesion());
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [isModalSimuladorOpen, setIsModalSimuladorOpen] = useState(false);
    const [isEncuestaArchivadasOpen, setIsEncuestaArchivadasOpen] = useState(false);


    const ACCION_VACIA = { accion: "", titulo: "", mensaje: "" }
    const ACCION_MODIFICAR = { accion: "modificar", titulo: "Modificar", mensaje: "¿seguro que deseas modificar la encuesta?" }
    const ACCION_ELIMINAR = { accion: "eliminar", titulo: "Eliminar", mensaje: "¿seguro que deseas eliminar la encuesta?" }
    const ACCION_ACTIVAR = { accion: "ACtivar", titulo: "Activar", mensaje: "Cuando la encuesta se active en un Spot, esta ya no se podrá modificar ni eliminar. Realice todos los cambios necesarios antes de continuar ¿estás seguro que deseas activar la encuesta?" }


    const [errorBuscar, setErrorBuscar] = useState('');
    const [textoBuscado, setTextoBuscado] = useState('');

    const [encuestas, setEncuestas] = useState([]);
    const [encuestaSelected, setEncuestaSelected] = useState(null);
    const [accionAConfirmar, setAccionAConfirmar] = useState(ACCION_VACIA);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const userData = useSelector(state => state.authInfo);




    const location = useLocation();

    const encuesta = location.state ? location.state.encuesta : null;



    // useEffect(() => {
    //     console.log("Nuevo texto a buscar ", textoBuscado)
    // }, [textoBuscado]);


    const abrirInformeRespuestasPorEncuesta = (encuestaSelected) => {

        navigate("informe-respuesta-por-encuesta", { state: { encuesta: encuestaSelected } })
    }


    const abrirInformeTendenciasEnLasRespuestas = (encuestaSelected) => {

        navigate("informe-tendencia-por-respuesta", { state: { encuesta: encuestaSelected } })
    }

    return (
        <div className={stylesPanel.containerPrincipal}>
            <HeaderTituloComponent pagina={HeaderTituloComponent.PAGINA_INFORMES_LISTA}></HeaderTituloComponent>
            <div className={stylesPanel.containerPanel}>
                <div className={styles.containerFilaBusqueda}>
                    <div>Listado de informes <h3>{encuesta.titulo}</h3></div>

                </div>



                <div className={styles.containerGrillaUsuarios}  >

                    <div className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                        <label className={styles.titulo}>{"Detalle de Respuestas por Encuesta"}</label>
                        <label className={styles.descripcion}>{"Un análisis detallado de las respuestas de cada encuesta. Aquí puedes ver cuántas personas respondieron cada pregunta y cómo se distribuyeron las respuestas. Este informe es útil para evaluar el impacto de cada encuesta y entender mejor la opinión de los encuestados"}</label>
                        <div className={styles.divBotonera}>
                            <BotonPrincipal text="Comenzar" onClick={() => abrirInformeRespuestasPorEncuesta(encuesta)} icono={BotonPrincipal.ICONO_ANALIZAR} />
                        </div>
                    </div>

                    <div className={styles.containerUsuario} style={{ marginBottom: '20px' }}>
                        <label className={styles.titulo}>{"Tendencias en las Respuestas"}</label>
                        <label className={styles.descripcion}>{"Este informe proporciona un análisis de las tendencias en las respuestas de las encuestas a lo largo del tiempo. Aquí podrás ver cómo han variado las respuestas en diferentes periodos, identificando patrones o cambios significativos en la opinión de los encuestados. Este análisis es útil para comprender el comportamiento y las percepciones de los usuarios en diferentes contextos o momentos del tiempo, y cómo factores externos pueden influir en sus respuestas."}</label>
                        <div className={styles.divBotonera}>
                            <BotonPrincipal text="Comenzar" onClick={() => abrirInformeTendenciasEnLasRespuestas(encuesta)} icono={BotonPrincipal.ICONO_ANALIZAR} />
                        </div>
                    </div>
                </div>

            </div>




        </div >
    )
}

export default ListaInformesPage;