import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = ({ data }) => {

    console.log("chartData", data)
    const chartData = formatDataForChart(data)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Gráfico de Respuestas por Alternativa y Periodo',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

const formatDataForChart = (pregunta) => {
    // Obtener todos los periodos únicos
    console.log("pregunta", pregunta)
    const periodos = [...new Set(pregunta.respuestas.map((r) => r.periodo))];
    console.log("periodos", periodos)

    // Obtener todas las alternativas únicas
    const alternativas = [...new Set(pregunta.respuestas.map((r) => r.alternativa))];
    console.log("alternativas", alternativas)

    // Crear la estructura de datasets para cada alternativa
    const datasets = alternativas.map((alternativa, index) => {
        return {
            label: alternativa,
            data: periodos.map((periodo) => {
                // Buscar la cantidad de respuestas para cada periodo y alternativa
                const respuesta = pregunta.respuestas.find((r) => r.periodo === periodo && r.alternativa === alternativa);
                return respuesta ? respuesta.cantidad : 0;
            }),
            backgroundColor: `rgba(${index * 50}, ${100 + index * 20}, ${150 + index * 10}, 0.5)`, // Colores diferentes para cada alternativa
            borderColor: `rgba(${index * 50}, ${100 + index * 20}, ${150 + index * 10}, 1)`,
            borderWidth: 1,
        };
    });
    console.log("datasets", datasets)
    console.log("salida", {
        labels: periodos, // Etiquetas en el eje X (periodos)
        datasets: datasets, // Datos organizados en series por alternativa
    })

    const salida = {
        labels: periodos, // Etiquetas en el eje X (periodos)
        datasets: datasets, // Datos organizados en series por alternativa
    }

    return salida;
};

export default BarChartComponent;

